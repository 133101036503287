import React from "react"
import {
  UncontrolledDropdown,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Media,
  Badge
} from "reactstrap"
import PerfectScrollbar from "react-perfect-scrollbar"
import * as Icon from "react-feather"
import ReactCountryFlag from "react-country-flag"
import { connect } from "react-redux"
import { FormattedMessage } from "react-intl"
import {
  buscarCarrinho,
  setItens,
  updateItem,
  removeItem
} from "../../../redux/actions/checkout/index"
import {
  buscarProdutos
} from "../../../redux/actions/shop/Index"
import { changeMode } from "../../../redux/actions/customizer/index"
import { history } from "../../../history"
import { IntlContext } from "../../../utility/context/Internationalization"
import noimage from '../../../assets/img/backgrounds/no_image.jpg'
import ReactTooltip from 'react-tooltip';
import { coreBase } from "../../../configs/coreBase"

const handleNavigation = (e, path) => {
  e.preventDefault()
  window.location.href = path
  //history.push(path)
}

const UserDropdown = props => {
  return (
    <DropdownMenu right>
      <DropdownItem
        tag="a"
        onClick={() => history.push('/perfil')}
      >
        <Icon.User size={14} className="mr-50" />
        <span className="align-middle"><FormattedMessage id="Profile"/></span>
      </DropdownItem>
      <DropdownItem tag="a" onClick={() => history.push('/meuspedidos')}>
        <Icon.Layers size={14} className="mr-50" />
        <span className="align-middle"><FormattedMessage id="Orders"/></span>
      </DropdownItem>
      <DropdownItem tag="a" onClick={() => history.push('/rescunhos')}>
        <Icon.Layers size={14} className="mr-50" />
        <span className="align-middle"><FormattedMessage id="Drafts"/></span>
      </DropdownItem>
      {/* <DropdownItem tag="a" href="#" onClick={e => handleNavigation(e, "/faq")}>
        <Icon.Phone size={14} className="mr-50" />
        <span className="align-middle"><FormattedMessage id="Contact"/></span>
      </DropdownItem> */}
      <DropdownItem divider />
      <DropdownItem
        tag="a"
        href="/"
        onClick={e => {
          e.preventDefault()
          return props.logoutWithJWT()
        }}
      >
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle"><FormattedMessage id="LogOut"/></span>
      </DropdownItem>
    </DropdownMenu>
  )
}

class NavbarUser extends React.PureComponent {
  state = {
    langDropdown: false,
    suggestions: [],
    fc: false,
    isAdmin: false,
    isSeller: false
  }

  async componentWillMount(){
    this.props.buscarCarrinho();

    var r = await coreBase.post("/is_admin")
    if(r != null) this.setState({
      isAdmin: true
    });

    var r = await coreBase.post("/is_seller")
    if(r != null) this.setState({
      isSeller: true
    })
  }

  removerItem = item => {
    if(JSON.parse(localStorage.checkout ? localStorage.checkout : null)) {
      var checkout = JSON.parse(localStorage.checkout ? localStorage.checkout : null);
      if(checkout.length == 1) {
        if(localStorage.rascunho === "1") {
          alert('Você está alterando um rascunho de pedido, e apagou todos os itens dele, é necessário pelo menos 1 outro item para manter a edição.');
          return;
        }
      }

      var i = checkout.findIndex(i => i.id+''+i.unid == item.CodProduto+''+item.Referencia);
      if(i > -1)
        checkout.splice(i, 1);
      
      localStorage.checkout = checkout === [] ? "" : JSON.stringify(checkout);
      
      this.props.buscarCarrinho();
    }
  }

  handleLangDropdown = () =>
    this.setState({ langDropdown: !this.state.langDropdown })

  render() {
    const renderCartItems = this.props.checkout.itens.map((item, i) => {
      return (
        <div className="cart-item" key={i}>
          <Media
            className="p-0"
            onClick={() => window.open("/produto?CodProduto="+item.CodProduto+'&Referencia='+item.Referencia, '_blank').focus()}
          >
            <Media className="text-center pr-0 mr-1" left>
            {
              item.image != null ? <img
              style={{ maxHeight: 70 }}
              className={`${
                item.imgClass
                  ? item.imgClass + " cart-item-img"
                  : "cart-item-img"
              }`}
              src={item.image}
              alt={'Item carrinho'}
            /> : <img
              style={{ maxHeight: 70 }}
              className={`${
                item.imgClass
                  ? item.imgClass + " cart-item-img"
                  : "cart-item-img"
              }`}
              src={noimage}
              alt={'Item carrinho'}
            />
            }
            </Media>
            <Media className="overflow-hidden pr-1 py-1 pl-0" body>
              <span className="item-title text-bold-500 d-block mb-50">
                {item.Desceq}
              </span>
              {/* <span className="item-desc font-small-2 text-truncate d-block">
                {item.desc}
              </span> */}
              <div className="d-flex justify-content-between align-items-center mt-1">
                <span className="align-middle d-block">{item.Quantidade} x {item.Valor.toLocaleString("pt-BR", { style: "currency" , currency:"BRL"})}</span>
                {/* <Icon.X
                  className="danger"
                  size={15}
                  onClick={e => {
                    e.stopPropagation()
                    this.removerItem(item)
                  }}
                /> */}
              </div>
            </Media>
          </Media>
        </div>
      )
    })

    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        { this.state.isAdmin ? <li className="dropdown-notification nav-item dropdown">
          <a className="nav-link position-relative" style={{ fontWeight: '700', marginTop: 10 }} onClick={() => window.location = '/panel'}>Admin</a>
        </li> : null}
        { this.state.isSeller ? <li className="dropdown-notification nav-item dropdown">
          <a className="nav-link position-relative" style={{ fontWeight: '700', marginTop: 10 }} onClick={() => window.location = '/pedidos'}>Pedidos</a>
        </li> : null}
        <li className="dropdown-notification nav-item dropdown">
          {
            this.props.customizer.customizer.theme == 'light' ? <a className="nav-link position-relative" style={{ fontWeight: '700' }} onClick={() => { this.props.changeMode('dark'); localStorage.mode = 'dark'}}><Icon.Moon /></a> : <a className="nav-link position-relative" style={{ fontWeight: '700' }} onClick={() => { this.props.changeMode('light'); localStorage.mode = 'light'}}><Icon.Sun/></a>
          }
        </li>
        {/* <IntlContext.Consumer>
          {context => {
            let langArr = {
              "en" : "English",
              "es" : "Espanhol",
              "pt" : "Portugues"
            }
            return (
              <Dropdown
                tag="li"
                className="dropdown-language nav-item"
                isOpen={this.state.langDropdown}
                toggle={this.handleLangDropdown}
                data-tour="language"
              >
                <DropdownToggle
                  tag="a"
                  className="nav-link"
                >
                  <ReactCountryFlag
                  className="country-flag"
                    countryCode={
                      context.state.locale === "en"
                        ? "us"
                        : context.state.locale === 'pt' ? 'br' : context.state.locale
                    }
                    svg
                  />
                  <span className="d-sm-inline-block d-none text-capitalize align-middle ml-50">
                    {langArr[context.state.locale]}
                  </span>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    tag="a"
                    onClick={e => {
                      localStorage.language = "pt";
                      context.switchLanguage("pt")
                    }}
                  >
                    <ReactCountryFlag className="country-flag" countryCode="br" svg />
                    <span className="ml-1">Portugues</span>
                  </DropdownItem>
                  <DropdownItem
                    tag="a"
                    onClick={e => {
                      localStorage.language = "es";
                      context.switchLanguage("es")
                    }}
                  >
                    <ReactCountryFlag className="country-flag" countryCode="es" svg />
                    <span className="ml-1">Espanhol</span>
                  </DropdownItem>
                  <DropdownItem
                    tag="a"
                    onClick={e => {
                      localStorage.language = "en";
                      context.switchLanguage("en")
                    }}
                  >
                    <ReactCountryFlag className="country-flag" countryCode="us" svg />
                    <span className="ml-1">English</span>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            )
          }}
        </IntlContext.Consumer> */}
        <UncontrolledDropdown
          tag="li"
          className="dropdown-notification nav-item"
        >
          <DropdownToggle tag="a" className="nav-link position-relative">
            <Icon.ShoppingCart size={21} />
            {this.props.checkout.itens.length > 0 ? (
              <Badge pill color="primary" className="badge-up">
                {this.props.checkout.itens.length}
              </Badge>
            ) : null}
          </DropdownToggle>
          <DropdownMenu
            tag="ul"
            right
            className={`dropdown-menu-media dropdown-cart ${
              this.props.checkout.itens.length === 0 ? "empty-cart" : ""
            }`}
          >
            <li
              className={`dropdown-menu-header ${
                this.props.checkout.itens.length === 0 ? "d-none" : "d-block"
              }`}
            >
              <div className="dropdown-header m-0">
                <h3 className="white">
                  {this.props.checkout.itens.length} <FormattedMessage id="Items"/>
                </h3>
                <span className="notification-title"><FormattedMessage id="InYourCart"/></span>
              </div>
            </li>
            <PerfectScrollbar
              className="media-list overflow-hidden position-relative"
              options={{
                wheelPropagation: false
              }}
            >
              {renderCartItems}
            </PerfectScrollbar>
            <li
              className={`dropdown-menu-footer border-top ${
                this.props.checkout.itens.length === 0 ? "d-none" : "d-block"
              }`}
            >
              <div
                className="dropdown-item p-1 text-center text-primary"
                onClick={() => window.location.href = "/checkout"}
              >
                <Icon.ShoppingCart size={15} />
                <span className="align-middle text-bold-600 ml-50">
                  <FormattedMessage id="FinishOrder"/>
                </span>
              </div>
            </li>
            <li
              className={`dropdown-menu-footer border-top ${
                this.props.checkout.itens.length === 0 ? "d-none" : "d-block"
              }`}
            >
              <div
                className="dropdown-item p-1 text-center text-primary"
                onClick={() => {
                  if(window.confirm('Tem certeza que deseja limpar o carrinho?')) {
                    localStorage.checkout = "";
                    localStorage.rascunho = "";
                    localStorage.NroPedido = "";
                    localStorage.PerDesc = "";
                    localStorage.CodTra = "";
                    localStorage.CodPag = "";
                    localStorage.Observacao = "";
                    localStorage.Redespacho = "";
                    localStorage.NomTra = "";
                    localStorage.deliveryMethod = "";
                    this.props.buscarCarrinho();
                    this.props.buscarProdutos();
                  }
                }}
              >
                <Icon.ShoppingCart size={15} />
                <span className="align-middle text-bold-600 ml-50">
                  <FormattedMessage id="Limpar"/>
                </span>
              </div>
            </li>
            <li
              className={`empty-cart ${
                this.props.checkout.itens.length > 0 ? "d-none" : "d-block"
              } p-2`}
            >
             <FormattedMessage id="EmptyCart"/>
            </li>
          </DropdownMenu>
        </UncontrolledDropdown>
        <li style={{ marginTop: 20, marginLeft: 5 }}>
          <h6
            data-for="main"
            data-tip={"\
            Total Itens: "+this.props.checkout.qtditens+"<br/><br/><br/>\
            Total Peças: "+this.props.checkout.qtdpecas+"<br/><br/><br/>\
            Subtotal: "+this.props.checkout.totalprod.toLocaleString("pt-BR", { style: "currency" , currency:"BRL"})+"<br/><br/><br/>\
            Total IPI: "+this.props.checkout.ipi.toLocaleString("pt-BR", { style: "currency" , currency:"BRL"})+"<br/><br/><br/>\
            Total + IPI: "+this.props.checkout.subtotal.toLocaleString("pt-BR", { style: "currency" , currency:"BRL"})+"\
          "}
            style={{ color: 'red', fontWeight: '700' }}
          >
              {this.props.checkout.subtotal.toLocaleString("pt-BR", { style: "currency" , currency:"BRL"})}
          </h6>
          <ReactTooltip id="main" place="bottom" type="light" multiline={true}/>
        </li>
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link" style={{ marginTop: 8 }}>
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">
                {this.props.userName}
              </span>
            </div>
            <span data-tour="user">
              <img
                src={this.props.userImg}
                className="round"
                height={"25"}
                width={"25"}
                alt="avatar"
              />
            </span>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
      </ul>
    )
  }
}

const mapStateToProps = state => {
  return {
    checkout: state.checkout,
    customizer: state.customizer,
    shop: state.shop
  }
}

export default connect(mapStateToProps, {
  setItens,
  removeItem,
  updateItem,
  buscarCarrinho,
  buscarProdutos,
  changeMode
})(NavbarUser)