import axios from 'axios';

const api = axios.create({
	// baseURL: "http://localhost:3333",
	baseURL: "https://belaflor-346420.rj.r.appspot.com",
	headers: {
	}
});

export default api;
