export function getCodByPermission(key, permissao) {
    var arr = permissao.split(';');
    console.log(arr)
    var index = arr.findIndex((e) => e.indexOf(key) > -1);
    console.log(index)
    if(index > -1) {
        arr = arr[index].split('|');
        console.log(arr)
        if(arr.length > 1) {
            console.log(arr[1])
            return arr[1];
        } else {
            return null;
        }
    }
}