const defaultStarred = []

const initialState = {
  filter: '%25',
  skip: 0,
  activePage: 1,
  initRange: 0,
  endRange: 100000000,
  vdaFutura: 'N',
  group: '0',
  orderby: 0,
  produtos: [],
  estoque: 0,
  qtdItens: 0,
  loading: false,
  codCli: null,
  codCid: null,
  modo: localStorage.modo ? localStorage.modo : 'CP1',
  segmento: localStorage.segmento ? localStorage.segmento : 'homedecor'
}

const shopReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        loading: !state.loading
      }
    case "SET_FILTER":
      return {
        ...state,
        filter: action.payload,
        skip: 0,
        activePage: 1
      }
    case "SET_ACTIVE_PAGE":
      return {
        ...state,
        activePage: action.payload,
        skip: action.payload-1
      }
    case 'SET_RANGE':
      return {
        ...state,
        initRange: action.payload.split(',')[0],
        endRange: action.payload.split(',')[1],
        skip: 0,
        activePage: 1
      }
    case 'SET_GROUP':
      return {
        ...state,
        group: action.payload,
        skip: 0,
        activePage: 1
      }
    case 'SET_PRODUTOS':
      return {
        ...state,
        produtos: action.payload,
      }
    case 'SET_CODCLI':
      return {
        ...state,
        codCli: action.payload,
      }
    case 'SET_MODO':
      return {
        ...state,
        modo: action.payload,
      }
    case 'SET_CODCID':
      return {
        ...state,
        codCid: action.payload,
      }
    case 'SET_ORDERBY':
      return {
        ...state,
        orderby: action.payload
      }
    case 'SET_ESTOQUE':
      return {
        ...state,
        estoque: action.payload
      }
    case 'SET_VENDAFUTURA':
      return {
        ...state,
        vdaFutura: action.payload
      }
    case 'SET_SEGMENTO':
      return {
        ...state,
        segmento: action.payload
      }
    case 'RESETAR_FILTROS':
      return {
        ...state,
        produtos: action.payload,
        filter: '%25',
        skip: 0,
        activePage: 1,
        initRange: 0,
        endRange: 100000000,
        group: '0'
      }
    case 'SET_QTDITENS':
      return {
        ...state,
        qtdItens: action.payload
      }

    default:
      return state
  }
}

export default shopReducer
