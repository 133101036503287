import api from './api';
import { history } from '../history';
import { getCodByPermission } from '../utility/utils';
import { coreBase } from './coreBase'

export const authenticate = async (user, reload) => {
  try {
    const response = await api.post("/sessions", {
      username: user.email,
      password: user.password
    });
    if(response.data) {
      var loggedInUser;
      loggedInUser = response.data
    
      localStorage.setItem('id', response.data.id);
      localStorage.setItem('username', user.email);
      localStorage.setItem('password', user.password);
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('permissoes', response.data.permissoes);
      localStorage.setItem('codCli', getCodByPermission('Cliente', response.data.permissoes));
      localStorage.setItem('codVen', getCodByPermission('CodVen', response.data.permissoes));
      localStorage.setItem('language', 'pt');
      
      var res = await coreBase.get('/ecommerce/cliente/'+getCodByPermission('Cliente', response.data.permissoes));
      if(res){
        if(res.data.length > 0) {
          var cliente = res.data[0];
          localStorage.setItem('endereco', cliente.ENDCLI+' '+cliente.NroEnd+', '+cliente.COMCLI+' - '+cliente.Cidade+' - '+cliente.Complemento);
          localStorage.setItem('email', cliente.EmaCli ? cliente.EmaCli : '');
          localStorage.setItem('ValVolumeKM', cliente.ValVolumeKM);
          localStorage.setItem('ValFreteMin', cliente.ValFreteMin);
        }
      }
    
      return true;
    } else {
      // if(window.location.href.indexOf('/pedido') > -1) return;
      if(reload) {
        window.location.href = '/';
      }
      localStorage.clear();
      return false;
    }
  } catch (error) {
    // if(window.location.href.indexOf('/pedido') > -1) return;
    if(reload) {
      window.location.href = '/';
    }
    localStorage.clear();
    return false;
  }
}